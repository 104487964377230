<template>
  <v-container>
    <contact-breadcrumb :contact="contact" />
    <app-error :error="error" v-if="error" />
    <app-loader large v-if="!contact"></app-loader>
    <contact-editor v-model="contact" v-else @submit="update($event)" />
  </v-container>
</template>

<script>
import ContactEditor from "@/components/contacts/ContactEditor";
import ContactBreadcrumb from "@/components/contacts/ContactBreadcrumb";

export default {
  props: {
    uid: { type: String, required: true }
  },
  data() {
    return {
      contact: null,
      error: null,
      breadcrumbs: []
    };
  },
  components: {
    ContactEditor,
    ContactBreadcrumb
  },
  created() {
    this.$store
      .dispatch("contacts/get", this.uid)
      .then(c => (this.contact = c))
      .catch(err => (this.error = err));
  },
  methods: {
    update(contact) {
      this.error = null;
      this.$store
        .dispatch("contacts/update", contact)
        .then(() => {
          this.$router.replace({
            name: "contacts/list"
          });
        })
        .catch(err => (this.error = err));
    }
  }
};
</script>

<style></style>
