<template>
  <v-breadcrumbs :items="breadcrumbs" />
</template>

<script>
export default {
  props: {
    contact: { type: Object, required: false }
  },
  data() {
    return {
      breadcrumbs: []
    };
  },
  methods: {
    breadcrumbs$() {
      this.breadcrumbs = [
        {
          text: this.$t("contacts.title"),
          href: "/ui/contacts",
          link: true
        },
        {
          text: this.getName(),
          disabled: true
        }
      ];
    },
    getName() {
      if (!this.contact) {
        return this.$t("contacts.title");
      }
      if (this.contact.company) {
        return this.contact.company;
      }
      if (this.company.familyName) {
        return this.contact.familyName;
      }
      if (this.company.email) {
        return this.contact.email;
      }

      return this.$t("contacts.title");
    }
  },
  watch: {
    contact: "breadcrumbs$"
  }
};
</script>

<style></style>
