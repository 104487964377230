<template>
  <ValidationObserver slim v-slot="{}" ref="observer">
    <v-card>
      <v-card-title class="headline" v-if="title">
        {{ title }}
      </v-card-title>
      <v-divider v-if="title" />
      <v-card-text>
        <app-error :error="error" v-if="error" />

        <v-container>
          <v-row>
            <v-col cols="12">
              <organization-selection
                :uid="
                  contact.organization ? contact.organization.uid : undefined
                "
                @change="organization"
              />
            </v-col>
          </v-row>

          <v-form key="password-form" @submit.prevent="submit()">
            <v-row>
              <v-col cols="12">
                <ValidationProvider>
                  <v-text-field
                    v-model.trim="contact.degree"
                    prepend-icon="person"
                    :label="$t('contacts.degree')"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('contacts.givenName')"
                >
                  <v-text-field
                    prepend-icon="person"
                    v-model.trim="contact.givenName"
                    :label="$t('contacts.givenName')"
                    :error-messages="errors"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('contacts.familyName')"
                >
                  <v-text-field
                    prepend-icon="person"
                    v-model.trim="contact.familyName"
                    :label="$t('contacts.familyName')"
                    :error-messages="errors"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :name="$t('contacts.street')"
                >
                  <v-text-field
                    prepend-icon="location_on"
                    v-model.trim="contact.street"
                    :label="$t('contacts.street')"
                    :error-messages="errors"
                    required
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('contacts.street2')"
                >
                  <v-text-field
                    prepend-icon="location_on"
                    v-model.trim="contact.street2"
                    :label="$t('contacts.street2')"
                    :error-messages="errors"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :name="$t('contacts.zip')"
                >
                  <v-text-field
                    prepend-icon="location_on"
                    v-model.trim="contact.zip"
                    :label="$t('contacts.zip')"
                    :error-messages="errors"
                    required
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :name="$t('contacts.city')"
                >
                  <v-text-field
                    prepend-icon="location_on"
                    v-model.trim="contact.city"
                    :label="$t('contacts.city')"
                    :error-messages="errors"
                    required
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  :name="$t('contacts.country')"
                >
                  <v-text-field
                    prepend-icon="location_on"
                    v-model.trim="contact.country"
                    :label="$t('contacts.country')"
                    :error-messages="errors"
                    required
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('contacts.province')"
                >
                  <v-text-field
                    prepend-icon="location_on"
                    v-model.trim="contact.province"
                    :label="$t('contacts.province')"
                    :error-messages="errors"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  rules="email"
                  v-slot="{ errors }"
                  :name="$t('contacts.email')"
                >
                  <v-text-field
                    prepend-icon="mail_outline"
                    v-model.trim="contact.email"
                    :label="$t('contacts.email')"
                    :error-messages="errors"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('contacts.website')"
                >
                  <v-text-field
                    prepend-icon="link"
                    v-model.trim="contact.website"
                    :label="$t('contacts.website')"
                    :error-messages="errors"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('contacts.phoneNumber')"
                >
                  <v-text-field
                    prepend-icon="phone"
                    v-model.trim="contact.phoneNumber"
                    :label="$t('contacts.phoneNumber')"
                    :error-messages="errors"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$t('contacts.mobileNumber')"
                >
                  <v-text-field
                    prepend-icon="phone"
                    v-model.trim="contact.mobileNumber"
                    :label="$t('contacts.mobileNumber')"
                    :error-messages="errors"
                    :readonly="readonly"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="submit()" fab color="secondary" v-if="!readonly">
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn fab @click="cancel()">
          <v-icon>cancel</v-icon>
        </v-btn>
        <v-btn
          fab
          @click="deleteLead()"
          v-if="contact.source === 'LEAD' && !readonly"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
import OrganizationSelection from "@/components/organizations/OrganizationSelection";

export default {
  props: {
    value: { type: Object, required: true },
    title: { type: String, required: false },
    readonly: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      contact: JSON.parse(JSON.stringify(this.value)), //do not muate value
      error: null,
    };
  },
  watch: {
    value() {
      this.contact = JSON.parse(JSON.stringify(this.value));
    },
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.$emit("submit", this.contact);
        }
      });
    },
    deleteLead() {
      if (this.contact.source === "LEAD") {
        this.$root
          .$confirm(this.$t("app.delete"), this.$t("app.deleteEntity") + "?")
          .then((confirm) => {
            if (confirm) {
              this.$store
                .dispatch("contacts/delete", this.contact)
                .then(() => this.$router.back())
                .catch((err) => (this.error = err));
            }
          });
      }
    },
    cancel() {
      if (this.$refs.observer.flags.dirty) {
        this.$root
          .$confirm(this.$t("app.discard"), this.$t("app.discardChanges") + "?")
          .then((confirm) => {
            if (confirm) {
              this.$router.back();
            }
          });
      } else {
        this.$router.back();
      }
    },
    organization(organization) {
      if (organization) {
        this.contact.organization = organization;
        this.contact.company = organization.name;

        if (this.contact.uid !== undefined && this.contact.source !== "LEAD")
          this.$store.dispatch("contacts/setOrganization", this.contact);
      } else {
        this.contact.organization = undefined;
        this.contact.company = undefined;
        if (this.contact.uid !== undefined && this.contact.source !== "LEAD")
          this.$store.dispatch("contacts/removeOrganization", this.contact.uid);
      }
    },
  },
  components: {
    OrganizationSelection,
  },
};
</script>

<style></style>
