<template>
  <v-container>
    <v-breadcrumbs
      :items="[
        {
          text: $t('contacts.title'),
          disabled: true,
        },
      ]"
    />
    <app-error v-if="error" :error="error" />
    <h1>{{ $t("contacts.title") }}</h1>
    <v-divider class="mb-2" />

    <v-card>
      <v-card-title>
        <contact-filter v-model="filters" />
        <v-spacer></v-spacer>
        <v-btn @click="download" fab small class="mr-3">
          <v-icon>download</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'contacts/create' }" color="secondary" fab small>
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-data-table
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 250, 500, 1000],
      }"
      :headers="headers"
      :items="contacts"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalCount"
      class="elevation-1"
      fixed-header
      item-key="uid"
      multi-sort
      @click:row="
        $router.push({ name: 'contacts/details', params: { uid: $event.uid } })
      "
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="item.source === 'LEAD'"
          :to="{ name: 'contacts/edit', params: { uid: item.uid } }"
          icon
          small
        >
          <v-icon small> edit</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.source="{ item }">
        <v-chip :color="getColor(item.source)" class="caption" dark>
          <span v-if="item.source === 'LEAD'">Maximi</span>
          <span v-else :title="$t('contacts.sources.' + item.source)">
            Wordpress
          </span>
        </v-chip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ContactFilter from "@/components/contacts/ContactFilter";

export default {
  data() {
    return {
      contacts: [],
      error: null,
      loading: false,
      totalCount: 0,
      filters: this.getFilters(),
      options: {},
      headers: [
        { text: "", value: "source", width: 128 },
        { text: this.$t("contacts.company"), value: "company" },
        { text: this.$t("contacts.degree"), value: "degree" },
        { text: this.$t("contacts.givenName"), value: "givenName" },
        { text: this.$t("contacts.familyName"), value: "familyName" },
        { text: this.$t("contacts.email"), value: "email" },
        { text: this.$t("contacts.zip"), value: "zip" },
        { text: this.$t("contacts.city"), value: "city" },
        { text: this.$t("contacts.province"), value: "province" },
        { text: "", value: "actions", sortable: false, width: 24 },
      ],
    };
  },
  components: {
    ContactFilter,
  },
  watch: {
    options: {
      handler() {
        this.fetchContacts();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.options.page = 1;
        this.storeFilters();
        this.fetchContacts();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchContacts();
  },
  methods: {
    getColor(source) {
      if (source === "LEAD") return "orange";
      else return "green";
    },
    fetchContacts() {
      this.loading = true;
      this.error = null;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var order = [];
      if (sortBy) {
        for (let i in sortBy) {
          order.push(sortBy[i] + ":" + (sortDesc[i] ? "desc" : "asc"));
        }
      }

      let params = {
        size: itemsPerPage,
        page: page,
        order: order,
        filters: this.filters,
      };

      this.$store
        .dispatch("contacts/list", params)
        .then((p) => {
          if (p) {
            this.contacts = p.items;
            this.totalCount = p.count;
          } else {
            this.contacts = [];
          }
        })
        .catch((err) => {
          this.error = err;
          this.contacts = [];
        })
        .finally(() => (this.loading = false));
    },
    download() {
      if (this.loading) return;

      const url = new URL("/api/v1/contacts", document.baseURI);
      if (this.filters) {
        Object.keys(this.filters).forEach((k) => {
          if (Array.isArray(this.filters[k]))
            this.filters[k].forEach((v) => url.searchParams.append(k, v));
          else url.searchParams.append(k, params.filters[k]);
        });
      }

      this.loading = true;
      this.error = null;
      this.$http
        .get(url.toString(), {
          headers: {
            Accept: "text/csv",
          },
        })
        .then((r) => {
          let blob = new Blob([r.data], { type: r.headers["content-type"] });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "maximi_contacts.csv";
          link.click();
        })
        .catch((err) => (this.error = err))
        .finally(() => (this.loading = false));
    },
    getFilters() {
      if (window.sessionStorage) {
        let filter = window.sessionStorage.getItem("ContactListView.Filter");
        if (filter) {
          return JSON.parse(filter);
        }
      }

      return {};
    },
    storeFilters() {
      if (window.sessionStorage) {
        window.sessionStorage.setItem(
          "ContactListView.Filter",
          JSON.stringify(this.filters)
        );
      }
    },
  },
};
</script>

<style></style>
