<template>
  <div class="d-flex">
    <v-icon>filter_list</v-icon>
    <div>
      <v-chip
        v-for="chip in chips"
        :key="chip.key + '.' + chip.index"
        close
        @click:close="chip.remove()"
        class="ml-3"
      >
        <span>{{ $t("contacts.filters." + chip.key) }}:&#160;</span>

        <span v-if="chip.key === 'source'">
          {{ $t("contacts.sources." + chip.value) }}
        </span>
        <span v-else>{{ chip.value }}</span>
      </v-chip>
    </div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn fab small icon v-on="on">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="givenName = true">
          {{ $t("contacts.filters.givenName") }}
        </v-list-item>
        <v-list-item @click="familyName = true">
          {{ $t("contacts.filters.familyName") }}
        </v-list-item>
        <v-list-item @click="email = true">
          {{ $t("contacts.filters.email") }}
        </v-list-item>
        <v-list-item @click="company = true">
          {{ $t("contacts.filters.company") }}
        </v-list-item>
        <v-list-item @click="zip = true">
          {{ $t("contacts.filters.zip") }}
        </v-list-item>
        <v-list-item @click="city = true">
          {{ $t("contacts.filters.city") }}
        </v-list-item>
        <v-list-item @click="country = true">
          {{ $t("contacts.filters.country") }}
        </v-list-item>
        <v-list-item @click="province = true">
          {{ $t("contacts.filters.province") }}
        </v-list-item>
        <v-list-item @click="source = true">
          {{ $t("contacts.filters.source") }}
        </v-list-item>
        <v-list-item @click="maximi = true">
          {{ $t("contacts.filters.group") }}
        </v-list-item>
      </v-list>
    </v-menu>
    <input-dialog
      :show="zip"
      :title="$t('contacts.filters.zip')"
      @input="add('zip', $event)"
      @show="show('zip', $event)"
    />
    <input-dialog
      :show="givenName"
      :title="$t('contacts.filters.givenName')"
      @input="add('givenName', $event)"
      @show="show('givenName', $event)"
    />
    <input-dialog
      :show="familyName"
      :title="$t('contacts.filters.familyName')"
      @input="add('familyName', $event)"
      @show="show('familyName', $event)"
    />
    <input-dialog
      :show="email"
      :title="$t('contacts.filters.email')"
      @input="add('email', $event)"
      @show="show('email', $event)"
    />
    <input-dialog
      :show="company"
      :title="$t('contacts.filters.company')"
      @input="add('company', $event)"
      @show="show('company', $event)"
    />
    <input-dialog
      :show="city"
      :title="$t('contacts.filters.city')"
      @input="add('city', $event)"
      @show="show('city', $event)"
    />
    <select-dialog
      :show="country"
      :title="$t('contacts.filters.country')"
      :label="$t('contacts.filters.country')"
      :items="countries"
      @input="add('country', $event)"
      @show="show('country', $event)"
    />
    <input-dialog
      :show="province"
      :title="$t('contacts.filters.province')"
      @input="add('province', $event)"
      @show="show('province', $event)"
    />
    <select-dialog
      :show="source"
      :title="$t('contacts.filters.source')"
      :label="$t('contacts.filters.source')"
      :items="sources"
      @input="add('source', $event)"
      @show="show('source', $event)"
    />
    <select-dialog
      :show="maximi"
      :title="$t('contacts.filters.group')"
      :label="$t('contacts.filters.group')"
      :items="groups"
      @input="add('maximi', $event)"
      @show="show('maximi', $event)"
    />
  </div>
</template>

<script>
import InputDialog from "@/components/shared/InputDialog";
import SelectDialog from "@/components/shared/SelectDialog";

export default {
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      filters: this.value,
      givenName: false,
      familyName: false,
      email: false,
      company: false,
      zip: false,
      city: false,
      country: false,
      province: false,
      source: false,
      sources: [
        { text: this.$t("contacts.sources.LEAD"), value: "LEAD" },
        { text: this.$t("contacts.sources.CUSTOMER"), value: "CUSTOMER" },
        {
          text: this.$t("contacts.sources.SUBSCRIPTION"),
          value: "SUBSCRIPTION",
        },
        { text: this.$t("contacts.sources.INVOICING"), value: "INVOICING" },
        { text: this.$t("contacts.sources.LOCATION"), value: "LOCATION" },
        {
          text: this.$t("contacts.sources.SERVICE_PROVIDER"),
          value: "SERVICE_PROVIDER",
        },
        {
          text: this.$t("contacts.sources.CONTACT_PERSON"),
          value: "CONTACT_PERSON",
        },
        { text: this.$t("contacts.sources.VENDOR"), value: "VENDOR" },
      ],
      maximi: false,
      countries: [
        { text: this.$t("countries.AT"), value: "AT" },
        { text: this.$t("countries.DE"), value: "DE" },
      ],
      groups: [
        { text: "Maximi", value: true },
        { text: "Wordpress", value: false },
      ],
    };
  },
  computed: {
    chips() {
      return Object.keys(this.filters)
        .map((k) => {
          if (!this.filters[k]) return [];

          if (!Array.isArray(this.filters[k])) {
            this.$delete(this.filters, k);
            return [];
          }

          return this.filters[k].map((v, i) => {
            const that = this;
            return {
              key: k === "maximi" ? "group" : k,
              value: k === "maximi" ? (v ? "Maximi" : "Wordpress") : v,
              index: i,
              remove: () => {
                that.filters[k].splice(i, 1);
                if (!that.filters[k] || that.filters[k].length < 1) {
                  that.$delete(that.filters, k);
                }
              },
            };
          });
        })
        .flat();
    },
  },
  components: {
    InputDialog,
    SelectDialog,
  },
  methods: {
    show(field, value) {
      this.$set(this, field, value);
    },
    add(field, val) {
      if (!this.filters[field]) {
        this.$set(this.filters, field, [val]);
      } else {
        this.filters[field].push(val);
      }
      this.show(field, false);
    },
  },
};
</script>

<style></style>
