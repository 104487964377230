<template>
  <v-container>
    <contact-breadcrumb :contact="contact" />
    <app-error :error="error" v-if="error" />
    <contact-editor
      :title="$t('contacts.titleCreate')"
      v-model="contact"
      @submit="create($event)"
      @cancel="cancel($event)"
    />
  </v-container>
</template>

<script>
import ContactEditor from "@/components/contacts/ContactEditor";
import ContactBreadcrumb from "@/components/contacts/ContactBreadcrumb";

export default {
  data() {
    return {
      contact: {},
      error: null
    };
  },
  components: {
    ContactEditor,
    ContactBreadcrumb
  },
  methods: {
    create(contact) {
      this.error = null;
      this.$store
        .dispatch("contacts/create", contact)
        .then(() => {
          this.$router.replace({
            name: "contacts/list"
          });
        })
        .catch(err => (this.error = err));
    }
  }
};
</script>

<style></style>
