var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-breadcrumbs',{attrs:{"items":[
      {
        text: _vm.$t('contacts.title'),
        disabled: true,
      },
    ]}}),(_vm.error)?_c('app-error',{attrs:{"error":_vm.error}}):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.$t("contacts.title")))]),_c('v-divider',{staticClass:"mb-2"}),_c('v-card',[_c('v-card-title',[_c('contact-filter',{model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"fab":"","small":""},on:{"click":_vm.download}},[_c('v-icon',[_vm._v("download")])],1),_c('v-btn',{attrs:{"to":{ name: 'contacts/create' },"color":"secondary","fab":"","small":""}},[_c('v-icon',[_vm._v("add")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
      itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 250, 500, 1000],
    },"headers":_vm.headers,"items":_vm.contacts,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalCount,"fixed-header":"","item-key":"uid","multi-sort":""},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.$router.push({ name: 'contacts/details', params: { uid: $event.uid } })}},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [(item.source === 'LEAD')?_c('v-btn',{attrs:{"to":{ name: 'contacts/edit', params: { uid: item.uid } },"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit")])],1):_vm._e()]}},{key:"item.source",fn:function({ item }){return [_c('v-chip',{staticClass:"caption",attrs:{"color":_vm.getColor(item.source),"dark":""}},[(item.source === 'LEAD')?_c('span',[_vm._v("Maximi")]):_c('span',{attrs:{"title":_vm.$t('contacts.sources.' + item.source)}},[_vm._v(" Wordpress ")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }